import React, { useEffect, useRef } from "react";
import classes from "./EducationPage.module.scss";
import Banner from "../../components/EducationComponents/Banner/Banner";
import TrendAnalysis from "../../components/EducationComponents/TrendAnalysis/TrendAnalysis";
import Shares from "../../components/EducationComponents/Shares/Shares";
import Options from "../../components/EducationComponents/Options/Options";
import Forex from "../../components/EducationComponents/Forex/Forex";
import ETFs from "../../components/EducationComponents/ETFs/ETFs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const EducationPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div className={classes.educationContainer}>
      <Banner />
      <div className={classes.col1} ref={col1}>
        <TrendAnalysis />
      </div>
      <div className={classes.col2} ref={col2}>
        <Shares />
      </div>
      <div className={classes.col3} ref={col3}>
        <Options />
      </div>
      <div className={classes.col4} ref={col4}>
        <Forex />
      </div>
      <div className={classes.col5} ref={col5}>
        <ETFs />
      </div>
    </div>
  );
};

export default EducationPage;
