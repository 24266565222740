import React from "react";
import classes from "./Invest.module.scss";
import img1 from "../../../assets/img/fees/1/1.png";
import { useTranslation } from "react-i18next";

const Invest = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.invest}>
      <div className={classes.investBody}>
        <div className="container">
          <div className={classes.investRow}>
            <div className={classes.investRowLeft}>
              <h2 className={[classes.investRowLeftTitle, "font-35"].join(" ")}>
                {t('invest_stock_title')}
              </h2>
              <p
                className={[classes.investRowLeftSubtitle, "font-20"].join(" ")}
              >
                {t('invest_stock_text')}
              </p>
              <ul className={classes.investRowLeftList}>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_02')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_03')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_04')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_05')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_06')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_07')}
                </li>
                <li className={[classes.investRowLeftListItem, "font-20"].join(" ")}>
                  {t('invest_stock_li_01')}
                </li>
              </ul>
            </div>
            <div className={classes.investRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invest;
