import React from "react";
import classes from "./Withdrawal.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Withdrawal = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.withdrawal}>
      <div className="container">
        <div className={classes.withdrawalBody}>
          <div className={[classes.withdrawalTitle, "font-35"].join(" ")}>
            {t('withdrawal_title')}
          </div>
          <div className={[classes.withdrawalSubtitle, "font-20"].join(" ")}>
            <Trans>
              {t('withdrawal_text')}
            </Trans>
          </div>
          <div className={classes.withdrawalWrapScroll}>
            <div className={classes.withdrawalWrap}>
              <div className={classes.withdrawalRow}>
                <div className={classes.withdrawalRowColumn}>
                  <p className={classes.fw600}>{t('withdrawal_col_01_title')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p className={classes.fw600}>{t('withdrawal_col_02_title')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p className={classes.fw600}>{t('withdrawal_col_03_title')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p className={classes.fw600}>
                  {t('withdrawal_col_04_title')}
                  </p>
                </div>
              </div>
              <div className={classes.withdrawalRow}>
                <div className={classes.withdrawalRowColumn}>
                  <p>USD</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>SEPA</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_03_free')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_04_row_01')}</p>
                </div>
              </div>
              <div className={classes.withdrawalRow}>
                <div className={classes.withdrawalRowColumn}>
                  <p>USD</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>WIRE</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_03_free')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_04_row_02')}</p>
                </div>
              </div>
              <div className={classes.withdrawalRow}>
                <div className={classes.withdrawalRowColumn}>
                  <p>USD</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>WIRE</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_03_free')}</p>
                </div>
                <div className={classes.withdrawalRowColumn}>
                  <p>{t('withdrawal_col_04_row_03')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.withdrawalText}>
            {t('withdrawal_desc')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
