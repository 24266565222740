import React, { useEffect, useRef } from "react";
import Brokerage from "../../components/FeesPageComponents/Brokerage/Brokerage";
import Cancellation from "../../components/FeesPageComponents/Cancellation/Cancellation";
import Invest from "../../components/FeesPageComponents/Invest/Invest";
import Low from "../../components/FeesPageComponents/Low/Low";
import Overview from "../../components/FeesPageComponents/Overview/Overview";
import Withdrawal from "../../components/FeesPageComponents/Withdrawal/Withdrawal";
import NewLetters from "../../components/MainPageComponents/NewLetters/NewLetters";
import classes from "./FeesPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const FeesPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  const col6 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col6.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col6.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div className={classes.fees}>
      <Brokerage />
      <div className={classes.col1} ref={col1}>
        <Invest />
      </div>
      <div className={classes.col2} ref={col2}>
        <Low />
      </div>
      <div className={classes.col3} ref={col3}>
        <Overview />
      </div>
      <div className={classes.col4} ref={col4}>
        <Withdrawal />
      </div>
      <div className={classes.col5} ref={col5}>
        <Cancellation />
      </div>
      <div className={classes.col6} ref={col6}>
        <NewLetters />
      </div>
    </div>
  );
};

export default FeesPage;
