import React, { useState } from "react";
import classes from './AccessTo.module.scss';
import gif from '../../../assets/img/ProductsPage/access_to.gif';
import { useTranslation } from "react-i18next";

const buttons = [
    {
        id: 1,
        text: 'Shares', 
    },
    {
        id: 2,
        text: 'ETFs', 
    },
    {
        id: 3,
        text: 'Stock options', 
    },
    {
        id: 4,
        text: 'Futures', 
    },
    {
        id: 5,
        text: 'Options on Futures', 
    },
    {
        id: 6,
        text: 'Obligations', 
    },
    {
        id: 7,
        text: 'Currencies', 
    },
    {
        id: 8,
        text: 'Investment Funds', 
    },
    {
        id: 9,
        text: 'Warrants', 
    },
];

const tabs = {
    1: [
        'Arca (NYSE)',
        'ArcaEdge',
        'BATS',
        'Chicago Stock Exchange (CHX)',
        'Direct Edge',
        'EDGEA',
        'IB VWAP Dealing Network',
        'IEX',
        'INET',
        'ISE Stock Exchange',
        'Knight Securities',
        'NASDAQ',
        'NASDAQ BX',
        'NASDAQ PSX',
        'New York Stock Exchange',
        'NSX',
        'NYSENATE',
        'NYSE American',
        'OTC Markets Pink',
        'Alpha ATS',
        'Aequitas NEO',
        'Aequitas NEO Bed',
        'Chi-X',
        'LYNX',
        'OMEGA ATS',
        'Canadian Securities Exchange',
        'Toronto Stock Exchange',
        'TSX-Venture',
        'Mexican Stock Exchange',
    ],
    2: [
        'Arca (NYSE)',
        'Chicago Board Options Exchange (CBOE)',
        'Chicago Stock Exchange (CHX)',
        'NASDAQ BX',
        'New York Stock Exchange',
        'NYSE American',
        'Chi-X',
        'OMEGA ATS',
        'PURE',
        'Toronto Stock Exchange',
        'Mexican Stock Exchange',
    ],
    3: [
        'BATS',
        'Boston Options Exchange',
        'C2',
        'Chicago Board Options Exchange',
        'EDGX Options Exchange',
        'ISE',
        'ISE Mercury',
        'GEMINI',
        'MIAX Options Exchange',
        'MIAX PEARL',
        'NASDAQ OMX BX Options Exchange',
        'NASDAQOM',
        'NYSE American',
        'Pacific Exchange',
        'Philadelphia Stock Exchange',
    ],
    4: [
        'CBOT',
        'CBOE Futures Exchange',
        'CME (Electronic-Globex)',
        'ERIS Exchange',
        'ICE Futures US (ICEUS)',
        'NASDAQ Futures Exchange (NFX)',
        'ICE Futures US (NYBOT)',
        'New York Mercantile Exchange',
        'NYSE Liffe',
        'OneChicago (ONE)',
    ],
    5: [
        'CBOE Futures Exchange',
        'CBOT',
        'CME (Electronic-Globex)',
        'ICE Futures US (NYBOT)',
        'New York Mercantile Exchange',
        'NYSE Liffe US',
    ],
    6: [
        'BondDesk',
        'BONDLARGE',
        'Knight Bond Point',
        'Knight BondPoint for Munis',
        'Knight BondPoint for US Government Securities',
        'MuniCenter',
        'NYSE Arca Bonds',
        'Timber Hill Auto-Ex Bonds',
        'Tradeweb',
        'Tradeweb for Munis',
        'Tradeweb for US Government Securities',
    ],
    7: [
        'IDEAL PRO',
    ],
    8: [
        '361 Capital Funds',
        'AAAMCO Funds',
        'ABR Dynamic Investment fFuds',
        'Aberdeen Funds',
        'ACM Funds',
        'Adirondack Funds',
        'Advisory Research Funds',
        'AGF Funds',
        'AINN Funds',
        'Akre Funds',
        'Algiers Funds Family',
        'Allianz',
        'AlphaCentric Funds',
        'ALPS Funds',
        'ALPS Smith Funds',
        'Altegris Funds',
        'American Beacon',
        'American Funds',
        'American Century Funds',
        'AMG Funds',
        'Anchor Capital Funds',
        'Anfield Capital Management',
        'Angel Oak Funds',
        'Aperture Investors',
        'Aquila Funds',
        'AQR Funds',
        'Arin',
        'Ashmore Funds',
        'Astor Funds',
        'ATAC Funds',
        'Ave Maria Funds',
        'AXS Investments',
        'Azzad Funds',
        'Baird Funds',
        'Baltic Funds',
        'Baron Funds',
        'Belmont Capital Funds',
        'BlackRock Funds',
        'BMO Funds',
        'BNY Mellon Funds',
        'Bridgeway Funds',
        'Brown Advisory Funds',
        'BTS Funds',
        'Buffalo Funds',
        'Calamos Funds',
        'Cambiar Funds',
        'Calvert Funds',
        'Cannabis Growth Fund',
        'Carillon Funds',
        'Catalyst Funds',
        'Causeway Funds',
        'Cavalier Funds',
        'Cavanal Hill Funds',
        'CBOE Vest Funds',
        'Cedar Ridge Funds',
        'Centerstone Funds',
        'Center Funds',
        'Champlain Funds',
        'Chartwell Investment Partners',
        'Chiron Funds',
        'Chou America Funds',
        'CMG Funds',
        'Columbia Funds',
        'Counterpoint Investment funds',
        'Crow Point Partners Funds',
        'Cullen Funds',
        'Davenport Funds',
        'Davis Funds',
        'Day Hagen Funds',
        'Deerpark Funds',
        'Dimensional Fund Advisors',
        'Dimensional Fund Advisors (DFA) Offshore Funds',
        'Direction Funds',
        'Dodge & Cox Funds',
        'Doubleline Funds',
        'Dreyfus',
        'Driehaus Investment funds',
        'Eaton Vance Investment funds',
        'Emerald Investment funds',
        'Empiric Funds',
        'Equinox Funds',
        'Euro Pacific Funds',
        'Eventide Funds',
        'Evermore Funds Trust',
        'Exceeded Funds',
        'Fairholme Funds Inc.',
        'Federated Investors',
        'Fidelity Funds',
        'First Eagle Funds',
        'First Investors Funds',
        'IMF Funds',
        'Forward Funds',
        'FPA Funds',
        'Frank Funds',
        'Franklin Templeton Investments',
        'Frost Funds',
        'FS Investments',
        'Fuller & Thaler Funds',
        'Gabelli Funds',
        'Gator Funds',
        'Gavekal Funds',
        'Gerstein Fisher Funds',
        'Goehring & Rozencwajg Funds',
        'Goodwood Funds',
        'Good Harbor Funds',
        'Gotham Funds',
        'GQG Partners',
        'Size Peak Funds',
        'Greenspring Funds',
        'Guggenheim',
        'Guinness Atkinson Funds',
        'Hancock Horizon Funds',
        'Harbor Funds',
        'Harding Loevner Funds',
        'Hartford Funds',
        'Harvest Edge Funds',
        'Hennessy Funds',
        'Highland Funds',
        'Highmore Funds',
        'Hodges Funds',
        'Horizon Fund',
    ],
    9: [
        'ArcaEdge (ARCAEDGE)',
        'Beat BYX (BYX)',
        'Bats Global Markets (BATS)',
        'Chicago Stock Exchange (CHX)',
        'Direct Edge (EDGEA)',
        'ISE Options Exchange (ISE)',
        'Knight Securities',
        'NASDAQ OMX PSX (PSX)',
        'NASDAQ/Island',
        'New York Stock Exchange (NYSE)',
        'NYSE Arca (ARCA)',
        'NYSE MKT (NYSE AMEX)',
        'OTC Markets Pink (PINK)',
    ],
};

const AccessTo = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    return (
        <section className="container">
            <div className={classes.accessTo}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t('access_to_title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>{t('access_to_text_01')}</p>
                        <p>{t('access_to_text_02')}</p>
                        <p>{t('access_to_text_03')}</p>
                    </div>
                </div>
                <img className={classes.gif} src={gif} alt='Access to 150 markets: all global exchanges at a glance'/>
                <div className={classes.tabs}>
                    <div className={classes.btns}>
                        {buttons.map((btn) =>
                            <div 
                                key={btn.id} 
                                className={`${classes.btn} ${btn.id === currentTab && classes.btnActive} no-select`}
                                onClick={() => setCurrentTab(btn.id)}
                            >
                                {btn.text}
                            </div>
                        )}
                    </div>
                    <div className={classes.tabsContentWr}>
                        <div className={classes.tabsContent}>
                            {tabs[currentTab].map((item, index) =>
                                <p key={index}>
                                    {item}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccessTo;
