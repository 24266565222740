import React, { useRef, useEffect, useState } from "react";
import classes from './WhyOpen.module.scss';
import image_01 from '../../../assets/img/StartPage/WhyOpen/card_01.png';
import image_02 from '../../../assets/img/StartPage/WhyOpen/card_02.png';
import image_03 from '../../../assets/img/StartPage/WhyOpen/card_03.png';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const WhyOpen = () => {
    const { t } = useTranslation();
    const tab01Ref = useRef(null);
    const tab02Ref = useRef(null);
    const tab03Ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    const [ tab03Active, setTab03Active ] = useState(false);
    useEffect(() => {
        $(tab01Ref.current).slideUp(0);
        $(tab02Ref.current).slideUp(0);
        $(tab03Ref.current).slideUp(0);
    }, []);
    return (
        <section className={["container", classes.container].join(' ')}>
            <div className={classes.whyOpen}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('why_open_title')}
                </h2>
                <div className={classes.contentWr}>
                    <div className={classes.content}>
                        <div className={`${classes.item} ${tab01Active && classes.itemActive}`}>
                            <div className={classes.itemTop}>
                                <img className={[classes.itemTopImage_01, classes.itemTopImage].join(' ')} src={image_01} alt='Competitive rates'/>
                                <div 
                                    className={classes.button}
                                    onClick={() => {
                                        $(tab01Ref.current).slideToggle(300);
                                        setTab01Active(value => !value);
                                    }}
                                ></div>
                            </div>
                            <div ref={tab01Ref} className={classes.itemCenter}>
                                <ul>
                                    <li>{t('why_open_card_01_li_01')}</li>
                                    <li>{t('why_open_card_01_li_02')}</li>
                                    <li>{t('why_open_card_01_li_03')}</li>
                                    <li>{t('why_open_card_01_li_04')}</li>
                                    <li>{t('why_open_card_01_li_05')}</li>
                                </ul>
                            </div>
                            <h3 className={classes.itemBottom}>
                                {t('why_open_card_01_title')}
                            </h3>
                        </div>

                        <div className={`${classes.item} ${tab02Active && classes.itemActive}`}>
                            <div className={classes.itemTop}>
                                <img className={[classes.itemTopImage_02, classes.itemTopImage].join(' ')} src={image_02} alt='Competitive rates'/>
                                <div 
                                    className={classes.button}
                                    onClick={() => {
                                        $(tab02Ref.current).slideToggle(300);
                                        setTab02Active(value => !value);
                                    }}
                                ></div>
                            </div>
                            <div ref={tab02Ref} className={classes.itemCenter}>
                                <ul>
                                    <li>{t('why_open_card_02_li_01')}</li>
                                    <li>{t('why_open_card_02_li_02')}</li>
                                    <li>{t('why_open_card_02_li_03')}</li>
                                    <li>{t('why_open_card_02_li_04')}</li>
                                    <li>{t('why_open_card_02_li_05')}</li>
                                </ul>
                            </div>
                            <h3 className={classes.itemBottom}>
                                {t('why_open_card_02_title')}
                            </h3>
                        </div>

                        <div className={`${classes.item} ${tab03Active && classes.itemActive}`}>
                            <div className={classes.itemTop}>
                                <img className={[classes.itemTopImage_03, classes.itemTopImage].join(' ')} src={image_03} alt='Competitive rates'/>
                                <div 
                                    className={classes.button}
                                    onClick={() => {
                                        $(tab03Ref.current).slideToggle(300);
                                        setTab03Active(value => !value);
                                    }}
                                ></div>
                            </div>
                            <div ref={tab03Ref} className={classes.itemCenter}>
                                <ul>
                                    <li>{t('why_open_card_03_li_01')}</li>
                                    <li>{t('why_open_card_03_li_02')}</li>
                                    <li>{t('why_open_card_03_li_03')}</li>
                                    <li>{t('why_open_card_03_li_04')}</li>
                                    <li>{t('why_open_card_03_li_05')}</li>
                                </ul>
                            </div>
                            <h3 className={classes.itemBottom}>
                                {t('why_open_card_03_title')}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyOpen;
