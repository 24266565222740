import React, { useEffect, useRef } from "react";
import AccessTo from "../../components/ProductsPage/AccessTo/AccessTo";
import Banner from "../../components/ProductsPage/Banner/Banner";
import InvestIn from "../../components/ProductsPage/InvestIn/InvestIn";
import Newsletters from "../../components/ProductsPage/Newsletters/Newsletters";
import UnderBanner from "../../components/ProductsPage/UnderBanner/UnderBanner";
import classes from "./ProductsPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ProductsPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div className="ovf-hidden">
      <Banner />
      <div className={classes.col1} ref={col1}>
        <UnderBanner />
      </div>
      <div className={classes.col2} ref={col2}>
        <InvestIn />
      </div>
      <div className={classes.col3} ref={col3}>
        <AccessTo />
      </div>
      <div className={classes.col4} ref={col4}>
        <Newsletters />
      </div>
    </div>
  );
};

export default ProductsPage;
