import React from "react";
import classes from './Requirements.module.scss';
import { useTranslation } from "react-i18next";

const Requirements = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.requirements}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('requirements_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p>{t('requirements_text_01')}</p>
                        <p>{t('requirements_text_02')}</p>
                    </div>
                    <div className={classes.col}>
                        <ul>
                            <li>{t('requirements_li_01')}</li>
                            <li>{t('requirements_li_02')}</li>
                            <li>{t('requirements_li_03')}</li>
                        </ul>
                        <p>{t('requirements_text_03')}</p>
                    </div>
                </div>
                <div className={classes.rect_01}></div>
                <div className={classes.rect_02}></div>
                <div className={classes.rect_03}></div>
                <div className={classes.rect_04}></div>
            </div>
        </section>
    );
};

export default Requirements;
