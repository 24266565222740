import React from "react";
import classes from "./Shares.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Shares = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.container, 'container'].join(" ")}>
            <h2 className={['font-35', classes.title].join(" ")}>{t('shares_title')}</h2>
            <div className={['row', classes.textMb70].join(" ")}>
                <div className="col-12 col-md4-6">
                    <div className={classes.itemWrapLeft}>
                        <p className={['font-20', classes.text].join(" ")}>{t('shares_text_01')}</p>
                        <p className={['font-20', classes.text].join(" ")}>{t('shares_text_02')}</p>
                        <p className={['font-20', classes.text].join(" ")}>{t('shares_text_03')}</p>
                        <p className={['font-20', classes.text].join(" ")}></p>
                    </div>
                </div>
                <div className={["col-12 col-md4-6", classes.imgWrap].join(" ")}>
                    <div className={classes.itemWrapRight}>
                        <p className={['font-20', classes.text].join(" ")}>{t('shares_text_04')}</p>
                        <p className={['font-20', classes.text].join(" ")}>{t('shares_text_05')}</p>
                    </div>
                </div>
            </div>
            <div className={classes.bannerBtnWrap}>
                <Button>{t('btn_start_trading')}</Button>
            </div>
        </div>
    )
}

export default Shares;