import React, { useEffect, useRef } from "react";
import Banner from "../../components/StartPage/Banner/Banner";
import Faq from "../../components/StartPage/Faq/Faq";
import OpeningAnAccount from "../../components/StartPage/OpeningAnAccount/OpeningAnAccount";
import Requirements from "../../components/StartPage/Requirements/Requirements";
import StepsFor from "../../components/StartPage/StepsFor/StepsFor";
import WhyOpen from "../../components/StartPage/WhyOpen/WhyOpen";
import classes from "./StartPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const StartPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div className="ovf-hidden">
      <Banner />
      <div className={classes.col1} ref={col1}>
        <Requirements />
      </div>
      <div className={classes.col2} ref={col2}>
        <OpeningAnAccount />
      </div>
      <div className={classes.col3} ref={col3}>
        <StepsFor />
      </div>
      <div className={classes.col4} ref={col4}>
        <WhyOpen />
      </div>
      <div className={classes.col5} ref={col5}>
        <Faq />
      </div>
    </div>
  );
};

export default StartPage;
