import React, { useEffect, useRef } from "react";
import First from "../../components/MainPageComponents/First/First";
import Invest from "../../components/MainPageComponents/Invest/Invest";
import Online from "../../components/MainPageComponents/Online/Online";
import Three from "../../components/MainPageComponents/Three/Three";
import RowIcons from "../../components/MainPageComponents/RowIcons/RowIcons";
import Rates from "../../components/MainPageComponents/Rates/Rates";
import Stock from "../../components/MainPageComponents/Stock/Stock";
import Markets from "../../components/MainPageComponents/Markets/Markets";
import NewLetters from "../../components/MainPageComponents/NewLetters/NewLetters";
import classes from "./MainPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MainPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  const col6 = useRef(null);
  const col7 = useRef(null);
  const col8 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col6.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col6.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col7.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col7.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col8.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col8.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div>
      <First />
      <div className={classes.col1} ref={col1}>
        <Invest />
      </div>
      <div className={classes.col2} ref={col2}>
        <Online />
      </div>
      <div className={classes.col3} ref={col3}>
        <Three />
      </div>
      <div className={classes.col4} ref={col4}>
        <RowIcons />
      </div>
      <div className={classes.col5} ref={col5}>
        <Rates />
      </div>
      <div className={classes.col6} ref={col6}>
        <Stock />
      </div>
      <div className={classes.col7} ref={col7}>
        <Markets />
      </div>
      <div className={classes.col8} ref={col8}>
        <NewLetters />
      </div>
    </div>
  );
};

export default MainPage;
