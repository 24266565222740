import React from "react";
import classes from "./Three.module.scss";
import { Trans, useTranslation } from "react-i18next";
const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.three}>
      <div className="container">
        <div className={classes.threeBody}>
          <div className={classes.threeRow}>
            <div className={classes.threeRowLeft}>
              <h2
                className={[
                  classes.threeRowLeftTitle,
                  "font-35",
                  classes.mb20,
                ].join(" ")}
              >
                {t('open_your_title')}
              </h2>
              <p
                className={[
                  classes.threeRowLeftSubtitle,
                  "font-20",
                  classes.mb15,
                ].join(" ")}
              >
                {t('open_your_text_01')}
              </p>
              <p
                className={[
                  classes.threeRowLeftSubtitle,
                  "font-20",
                  classes.mb20,
                ].join(" ")}
              >
                {t('open_your_text_02')}
              </p>
            </div>
            <div className={classes.threeRowRight}>
              <h2
                className={[
                  classes.threeRowRightTitle,
                  "font-35",
                  classes.mb20,
                ].join(" ")}
              >
                {t('the_trading_title')}
              </h2>
              <p
                className={[classes.threeRowRightSubtitle, "font-20"].join(" ")}
              >
                <Trans>
                  {t('the_trading_text')}
                </Trans>
              </p>
            </div>
          </div>
          <div className={classes.threeBottom}>
            <h2
              className={[
                classes.threeBottomTitle,
                "font-35",
                classes.mb40,
              ].join(" ")}
            >
              {t('the_possibilities_title')}
            </h2>
            <p className={[classes.threeBottomSubtitle, "font-20"].join(" ")}>
              <Trans>{t('the_possibilities_text')}</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
