import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  return (
    <a 
      href="https://user.erotrade.ltd/signup"
      className={classes.button} 
      {...props}
    >
      <span>{props.children}</span>
    </a>
  );
};

export default Button;
