import React from "react";
import classes from "./Cancellation.module.scss";
import { useTranslation } from "react-i18next";

const Cancellation = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.cancellation}>
      <div className="container">
        <div className={classes.cancellationBody}>
          <div className={[classes.cancellationTitle, "font-35"].join(" ")}>
            {t('ovw_title')}
          </div>
          <div className={classes.cancellationWrapScroll}>
            <div className={classes.cancellationWrap}>
              <div className={classes.cancellationRow}>
                <div className={classes.cancellationRowColumn}>
                  <p className={classes.fw600}>{t('ovw_col_01_title')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p className={classes.fw600}>{t('ovw_col_02_title')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p className={classes.fw600}>{t('ovw_col_03_title')}</p>
                </div>
              </div>
              <div className={classes.cancellationRow}>
                <div className={classes.cancellationRowColumn}>
                  <p>{t('ovw_col_01_row_01')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p>$0.01 </p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p>
                    {t('ovw_col_03_row_01')}
                  </p>
                </div>
              </div>
              <div className={classes.cancellationRow}>
                <div className={classes.cancellationRowColumn}>
                  <p>{t('ovw_col_01_row_02')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p>$0.50 </p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p>
                    {t('ovw_col_03_row_02')}
                  </p>
                </div>
              </div>
              <div className={classes.cancellationRow}>
                <div className={classes.cancellationRowColumn}>
                  <p>{t('ovw_col_01_row_03')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p>{t('withdrawal_col_03_free')}</p>
                </div>
                <div className={classes.cancellationRowColumn}>
                  <p> —</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
