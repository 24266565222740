import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const hostname = document.location.hostname;
  return (
    <div className={classes.footer}>
      <div className={classes.footerBody}>
        <div className="container">
          <div className={classes.footerRow}>
            <div className={classes.footerRowLeft}>
              <p className={classes.footerRowLeftText}>
                {t('footer_text_01')}
              </p>
              <p className={classes.footerRowLeftText}>
                {t('footer_text_02')}
              </p>
            </div>
            <div className={classes.footerRowCenter}>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" className={classes.footerRowCenterItem}>
                {t('footer_terms_conditions')}
              </a>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className={classes.footerRowCenterItem}>
                {t('footer_privacy_policy')}
              </a>
              <a href="/docs/AML_POLICY.pdf" target="_blank" className={classes.footerRowCenterItem}>
                {t('footer_aml_policy')}
              </a>
            </div>
            <div className={classes.footerRowRight}>
              {/* <p className={classes.footerRowRightText}>
                You can contact us at any time, our team is always in touch!
              </p> */}
              <p className={classes.footerRowRightContact}>{t('footer_contacts')}:</p>
              <a href="tel:+441615099411" className={classes.footerRowRightPhone}>
                +441615099411
              </a>
              <a href={`mailto:support@${hostname}`} className={classes.footerRowRightEmail}>
                support@{hostname}
              </a>
            </div>
          </div>
          <div className={classes.rights}>
            Ero Trade © 2024. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
