import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './UnderBanner.module.scss';
import image_underbanner from '../../../assets/img/ProductsPage/underbanner.png';
import { useTranslation } from "react-i18next";

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.underBanner}>
            <div className="container">
                <div className={classes.contentWr}>
                    <div className={[classes.content, 'font-20'].join(' ')}>
                        <div className={classes.col}>
                            <p>{t('products_banner_text_01')}</p>
                        </div>
                        <div className={classes.col}>
                            <p>{t('products_banner_text_02')}</p>
                            <p>{t('products_banner_text_03')}</p>
                        </div>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_start_investing')}</Button>
                    </div>
                    <img className={classes.image} src={image_underbanner} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
