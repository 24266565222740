import React, { useRef, useEffect, useState } from "react";
import classes from './Faq.module.scss';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const Faq = () => {
    const { t } = useTranslation();
    const tab01Ref = useRef(null);
    const tab02Ref = useRef(null);
    const tab03Ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    const [ tab03Active, setTab03Active ] = useState(false);
    useEffect(() => {
        $(tab01Ref.current).slideUp(0);
        $(tab02Ref.current).slideUp(0);
        $(tab03Ref.current).slideUp(0);
    }, []);
    return (
        <section className="container">
            <div className={classes.faq}>
                <div className={[classes.title, 'font-35'].join(' ')}>
                    {t('faq_title')}
                </div>
                <div className={`${classes.spoilers} no-select`}>
                    {/* 1 */}
                    <div 
                        className={`${classes.spoiler} ${tab01Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab01Ref.current).slideToggle(300);
                            setTab01Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                1
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                {t('faq_01_title')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab01Ref} className={classes.spoilerContent}>
                            <ul>
                                <li>{t('faq_01_li_01')}</li>
                                <li>{t('faq_01_li_02')}</li>
                                <li>{t('faq_01_li_03')}</li>
                                <li>{t('faq_01_li_04')}</li>
                                <li>{t('faq_01_li_05')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* 2 */}
                    <div 
                        className={`${classes.spoiler} ${tab02Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab02Ref.current).slideToggle(300);
                            setTab02Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                2
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                {t('faq_02_title')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab02Ref} className={classes.spoilerContent}>
                            <p>{t('faq_02_text_01')}</p>
                            <p>{t('faq_02_text_02')}</p>
                        </div>
                    </div>
                    {/* 3 */}
                    <div 
                        className={`${classes.spoiler} ${tab03Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab03Ref.current).slideToggle(300);
                            setTab03Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                3
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                {t('faq_03_title')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab03Ref} className={classes.spoilerContent}>
                            <p>{t('faq_03_text_01')}</p>
                            <p>{t('faq_03_text_02')}</p>
                            <p>{t('faq_03_text_03')}</p>
                            <ul>
                                <li>{t('faq_03_li_01')}</li>
                                <li>{t('faq_03_li_02')}</li>
                                <li>{t('faq_03_li_03')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
