import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Invest.module.scss";
import { useTranslation } from "react-i18next";
 
const Invest = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.invest}>
      <div className={classes.investBody}>
        <div className="container">
          <div className={classes.cont}>
            <div className={classes.investWrapper}>
              <h2 className={[classes.investTitle, "font-35"].join(" ")}>
                {t('invest_in_title')}
              </h2>
              <p className={[classes.investSubtitle, "font-20"].join(" ")}>
                {t('invest_in_text_01')}
              </p>
              <ul className={classes.investList}>
                <li className={[classes.investListItem, "font-20"].join(" ")}>
                  {t('invest_in_li_01')}
                </li>
                <li className={[classes.investListItem, "font-20"].join(" ")}>
                  {t('invest_in_li_02')}
                </li>
                <li className={[classes.investListItem, "font-20"].join(" ")}>
                  {t('invest_in_li_03')}
                </li>
              </ul>
              <p className={[classes.investText, "font-20"].join(" ")}>
                {t('invest_in_text_02')}
              </p>
              <div className={classes.investBtn}>
                <Button>{t('btn_start_now')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invest;
