import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Rates.module.scss";
import img1 from "../../../assets/img/main/6/1.png";
import { useTranslation } from "react-i18next";

const Rates = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.rates}>
      <div className="container">
        <div className={classes.ratesBody}>
          <div className={classes.ratesRow}>
            <div className={classes.ratesRowLeft}>
              <h2 className={[classes.ratesRowLeftTitle, "font-35"].join(" ")}>
                {t('stock_exchanges_title')}
              </h2>
              <p
                className={[
                  classes.ratesRowLeftSubtitle,
                  "font-20",
                  classes.mb15,
                ].join(" ")}
              >
                {t('stock_exchanges_subtitle')}
              </p>
              <p
                className={[classes.ratesRowLeftSubtitle, "font-20"].join(" ")}
              >
                {t('stock_exchanges_text')}
              </p>
              <p
                className={[classes.ratesRowLeftSubtitle, "font-20"].join(" ")}
              ></p>
              <div className={classes.ratesRowLeftBtn}>
                <Button>{t('btn_start_investing')}</Button>
              </div>
            </div>
            <div className={classes.ratesRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rates;
