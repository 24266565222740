import img1 from "./assets/img/EducationPage/banner-bg.png"
import img2 from "./assets/img/EducationPage/banner-mobile-bg.png"
import img3 from "./assets/img/fees/1/1.png"
import img4 from "./assets/img/fees/1/mob.png"
import img5 from "./assets/img/main/1/1.png"
import img6 from "./assets/img/main/1/11.png"
import img7 from "./assets/img/main/1/bg.png"
import img8 from "./assets/img/StartPage/Banner/bear.png"

import img9 from "./assets/img/ProductsPage/banner.png"
import img10 from "./assets/img/ProductsPage/underbanner.png"
import img11 from "./assets/img/AboutPage/banner.png"



export const IMAGES = [
    {
        id: 0,
        url:
            img1
    },
    {
        id: 1,
        url:
            img2
    },
    {
        id: 2,
        url:
            img3
    },
    {
        id: 3,
        url:
            img4
    },
    {
        id: 4,
        url:
            img5
    },
    {
        id: 5,
        url:
            img6
    },
    {
        id: 6,
        url:
            img7
    },
    {
        id: 7,
        url:
            img8
    },
    {
        id: 8,
        url:
            img9
    },
    {
        id: 9,
        url:
            img10
    },

    {
        id: 10,
        url:
            img11
    },

]
