import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./NewLetters.module.scss";
import { useTranslation } from "react-i18next";

const NewLetters = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.NewLetters}>
      <div className={classes.NewLettersBody}>
        <div className="container">
          <div className={classes.NewLettersRow}>
            <div className={classes.NewLettersRowLeft}>
              <div className={classes.NewLettersRowLeftWrapper}>
                <h2
                  className={[classes.NewLettersRowLeftTitle, "font-35"].join(
                    " "
                  )}
                >
                  {t('newsletters_title')}
                </h2>
                <p
                  className={[
                    classes.NewLettersRowLeftSubtitle,
                    "font-20",
                    classes.fw600,
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('newsletters_subtitle')}
                </p>
                <p
                  className={[
                    classes.NewLettersRowLeftSubtitle,
                    "font-20",
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('newsletters_text_01')}
                </p>
                <p
                  className={[
                    classes.NewLettersRowLeftSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t('newsletters_text_02')}
                </p>
              </div>
              <div className={classes.NewLettersRowLeftBot}>
                <div className={classes.NewLettersRowLeftBotEmail}>
                  <input
                    type="text"
                    placeholder={t('newsletters_your_email')}
                    className={"font-18"}
                  />
                </div>
                <div className={classes.NewLettersRowLeftSubs}>
                  <Button>{t('btn_subscribe')}</Button>
                </div>
              </div>
            </div>
            <div className={classes.NewLettersRowRight}>
              <div className={classes.NewLettersRowRightWrapper}>
                <h2
                  className={[classes.NewLettersRowRightTitle, "font-35"].join(
                    " "
                  )}
                >
                  {t('open_an_account_title')}
                </h2>
                <p
                  className={[
                    classes.NewLettersRowRightSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t('open_an_account_text')}
                </p>
              </div>
              <div className={classes.NewLettersRowRightBotOpen}>
                <Button>{t('btn_open_an_account_now')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLetters;
