import React from "react";
import classes from "./ETFs.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img from "../../../assets/img/EducationPage/efts-img.png";
import { useTranslation } from "react-i18next";

const ETFs = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.container, 'container'].join(" ")}>
            <div className={['row', classes.textMb70].join(" ")}>
                <div className="col-12 col-md4-6">
                    <div className={classes.itemWrapLeft}>
                        <h2 className={['font-35', classes.title].join(" ")}>{t('etfs_title')}</h2>
                        <p className={['font-20', classes.text].join(" ")}>{t('etfs_text_01')}</p>
                        <p className={['font-20', classes.text].join(" ")}>{t('etfs_text_02')}</p>
                    </div>
                    <div className={classes.btnWrap}>
                        <Button>{t('btn_start_trading')}</Button>
                    </div>
                </div>
                <div className="col-12 col-md4-6">
                    <div className={classes.imgWrap}>
                        <img src={img} className={classes.img} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ETFs;