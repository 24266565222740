import React, { useState } from "react";
import classes from './Newsletters.module.scss';
import newsletters from '../../../assets/img/ProductsPage/newsletters.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Newsletters = () => {
    const { t } = useTranslation();
    const [ email, setEmail ] = useState('');
    return (
        <section className="container">
            <div className={classes.newsletters}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t('newsletters_title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p><b>{t('newsletters_subtitle')}</b></p>
                        <p>{t('newsletters_text_01')}</p>
                        <p>{t('newsletters_text_02')}</p>
                    </div>
                    <div className={classes.form}>
                        <input 
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder={t('newsletters_your_email')}
                        />
                        <div className={classes.btn}>
                            <Button>{t('btn_subscribe')}</Button>
                        </div>
                    </div>
                </div>
                <img src={newsletters} alt='Newsletters: the latest stock market news by e-mail'/>
            </div>
        </section>
    );
};

export default Newsletters;
