import React from "react";
import classes from './Banner.module.scss';
import image from '../../../assets/img/ProductsPage/banner.png';
import { useTranslation } from "react-i18next";

const cards = [
    {
        id: 1,
        title: '150',
        text: 'products_banner_card_01_text',
    },
    {
        id: 2,
        title: '33',
        text: 'products_banner_card_02_text',
    },
    {
        id: 3,
        title: '26',
        text: 'products_banner_card_03_text',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        {t('products_banner_title')}
                    </h1>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('products_banner_subtitle')}
                    </p>
                    <div className={classes.cards}>
                        {cards.map((card) =>
                            <div key={card.id} className={classes.card}>
                                <p className={classes.cardTitle}>
                                    {card.title}
                                </p>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt=''/>
                </div>
                <div className={classes.rect_01}></div>
            </div>
        </section>
    );
};

export default Banner;
