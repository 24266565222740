import React, { useEffect, useRef } from "react";
import classes from "./AccountsPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Banner from "../../components/AccountsPage/Banner/Banner";
import HowSelect from "../../components/AccountsPage/HowSelect/HowSelect";
import Accounts from "../../components/AccountsPage/Accounts/Accounts";
gsap.registerPlugin(ScrollTrigger);

const AccountsPage = () => {
  const col2 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div>
      <Banner />
      <div>
        <Accounts />
      </div>
      <div className={classes.col2} ref={col2}>
        <HowSelect />
      </div>
    </div>
  );
};

export default AccountsPage;
