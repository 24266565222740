import React from "react";
import classes from "./TrendAnalysis.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img from "../../../assets/img/EducationPage/trend-analysis-img.png";
import { useTranslation } from "react-i18next";

const TrendAnalysis = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={classes.trendAnalysisContainer}>
                <div className={['container'].join(" ")}>
                    <h1 className={['font-35', classes.title].join(" ")}>{t('trend_analysis_title')}</h1>
                    <div className={['row', classes.row].join(" ")}>
                        <div className="col-12 col-md4-6">
                            <div className={classes.itemWrapLeft}>
                                <p className={['font-20', classes.text].join(" ")}>{t('trend_analysis_text_01')}</p>
                                <p className={['font-20', classes.text, classes.textMb70].join(" ")}>{t('trend_analysis_text_02')}</p>
                            </div>
                            <div className={classes.bannerBtnWrap}>
                                <Button>{t('btn_start_trading')}</Button>
                            </div>
                        </div>
                        <div className={["col-12 col-md4-6", classes.imgWrap].join(" ")}>
                            <img src={img} className={classes.img} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.bannerBtnWrapMobile}>
                <Button>{t('btn_start_trading')}</Button>
            </div>
        </div>
    )
}

export default TrendAnalysis;