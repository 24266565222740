import React from "react";
import classes from "./Markets.module.scss";
import r from "../../../assets/img/main/8/phone.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase)

const Markets = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        element.querySelector("#intel"),
        {
          y: 0,
        },
        {
          y: 30,
          yoyo: true,
          duration: 4,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
          ),
          repeat: -1,
        }
      );
      gsap.fromTo(
        element.querySelector("#nokia"),
        {
          y: 0,
        },
        {
          y: 50,
          yoyo: true,
          duration: 4,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
          ),
          repeat: -1,
        }
      );
      gsap.fromTo(
        element.querySelector("#unilever"),
        {
          y: -10,
        },
        {
          y: 50,
          yoyo: true,
          duration: 6,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
          ),
          repeat: -1,
        }
      );
      gsap.fromTo(
        element.querySelector("#nestle"),
        {
          y: 0,
        },
        {
          y: 50,
          yoyo: true,
          duration: 5,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
          ),
          repeat: -1,
        }
      );
    }
  }, []);
  return (
    <div className={classes.markets} ref={ref}>
      <div className={classes.marketsBody}>
        <div className="container">
          <div className={classes.marketsRow}>
            <div className={classes.marketsRowLeft}>
              <h2
                className={[classes.marketsRowLeftTitle, "font-35"].join(" ")}
              >
                {t("the_markets_title")}
              </h2>
              <p
                className={[
                  classes.marketsRowLeftSubtitle,
                  "font-20",
                  classes.mb15,
                ].join(" ")}
              >
                {t("the_markets_text_01")}
              </p>
              <p
                className={[classes.marketsRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("the_markets_text_02")}
              </p>
              <ul className={classes.marketsRowLeftList}>
                <li
                  className={[classes.marketsRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("the_markets_li_01")}
                </li>
                <li
                  className={[classes.marketsRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("the_markets_li_02")}
                </li>
                <li
                  className={[classes.marketsRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("the_markets_li_03")}
                </li>
                <li
                  className={[classes.marketsRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("the_markets_li_04")}
                </li>
              </ul>
            </div>
            <div className={classes.marketsRowRight}>
              <img src={r} alt="" />
              <div
                className={[classes.marketsRowRight1, ""].join(" ")}
                id="intel"
              ></div>
              <div
                className={[classes.marketsRowRight2, ""].join(" ")}
                id="nokia"
              ></div>
              <div
                className={[classes.marketsRowRight3, ""].join(" ")}
                id="unilever"
              ></div>
              <div
                className={[classes.marketsRowRight4, ""].join(" ")}
                id="nestle"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets;
