import React, { useEffect, useRef } from "react";
import classes from "./OpeningAnAccount.module.scss";
import steps from "../../../assets/img/StartPage/OpeningAnAccount/steps.png";
import step_01 from "../../../assets/img/StartPage/OpeningAnAccount/step_01.png";
import step_02 from "../../../assets/img/StartPage/OpeningAnAccount/step_02.png";
import step_03 from "../../../assets/img/StartPage/OpeningAnAccount/step_03.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const OpeningAnAccount = () => {
  const { t } = useTranslation();
  const mainRef = useRef(null);
  const col1 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);

  return (
    <section className="container" ref={mainRef}>
      <div className={classes.openingAnAccount}>
        <h2 className={[classes.title, "font-35"].join(" ")}>
          {t("opening_an_account_title")}
        </h2>
        <div className={classes.content}>
          <img
            className={classes.stepsImg}
            src={steps}
            alt="Opening an Account Has Never Been So Easy"
            ref={col1}
          />
          <div className={[classes.step_01, classes.step].join(" ")}>
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_01_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_01_text")}
            </p>
          </div>
          <div className={[classes.step_02, classes.step].join(" ")}>
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_02_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_02_text")}
            </p>
          </div>
          <div className={[classes.step_03, classes.step].join(" ")}>
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_03_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_03_text")}
            </p>
          </div>
        </div>
        <div className={classes.contentMob}>
          <div className={[classes.step_01, classes.step].join(" ")}>
            <img className={classes.stepImg} src={step_01} alt="" />
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_01_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_01_text")}
            </p>
          </div>
          <div className={[classes.step_02, classes.step].join(" ")}>
            <img className={classes.stepImg} src={step_02} alt="" />
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_02_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_02_text")}
            </p>
          </div>
          <div className={[classes.step_03, classes.step].join(" ")}>
            <img className={classes.stepImg} src={step_03} alt="" />
            <h3 className={[classes.stepTitle, "font-20"].join(" ")}>
              {t("opening_an_account_card_03_title")}
            </h3>
            <p className={classes.stepText}>
              {t("opening_an_account_card_03_text")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpeningAnAccount;
