import React, { useEffect, useRef } from "react";
import AboutInvest from "../../components/AboutPage/AboutInvest/AboutInvest";
import Banner from "../../components/AboutPage/Banner/Banner";
import InvestFair from "../../components/AboutPage/InvestFair/InvestFair";
import StartInvesting from "../../components/AboutPage/StartInvesting/StartInvesting";
import TheInvest from "../../components/AboutPage/TheInvest/TheInvest";
import TheOnline from "../../components/AboutPage/TheOnline/TheOnline";
import TheOnlineBroker from "../../components/AboutPage/TheOnlineBroker/TheOnlineBroker";
import classes from "./AboutPage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  const col6 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col6.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col6.current,
          start: "top bottom",
          end: "+=200px",
          scrub: 3,
          id: "scrub",
          once: true,
        },
      });
    }
  }, []);
  return (
    <div className="ovf-hidden">
      <Banner />
      <div className={classes.col1} ref={col1}>
        <StartInvesting />
      </div>
      <div className={classes.col2} ref={col2}>
        <TheOnline />
      </div>
      <div className={classes.col3} ref={col3}>
        <TheOnlineBroker />
      </div>
      <div className={classes.col4} ref={col4}>
        <InvestFair />
      </div>
      <div className={classes.col5} ref={col5}>
        <TheInvest />
      </div>
      <div className={classes.col6} ref={col6}>
        <AboutInvest />
      </div>
    </div>
  );
};

export default AboutPage;
