import React, { useEffect, useRef, useState } from "react";
import classes from "./Low.module.scss";
import img1 from "../../../assets/img/fees/2/mob.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Low = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const { t } = useTranslation();
  const col1 = useRef(null);
  const col2 = useRef(null);

  let left1 = 0;
  if (width < 1421) {
    left1 = -100;
  }
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col2.current, {
        top: 0,
        left: left1,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      });
    }
    // eslint-disable-next-line
  }, [width]);
  return (
    <div className={classes.low} ref={col1}>
      <div className="container">
        <div className={classes.lowBody}>
          <div className={[classes.lowImg1, ""].join(" ")} ref={col2}></div>

          <div className={[classes.lowTitle, "font-35"].join(" ")}>
            {t("invest_adv_title")}
          </div>
          <div className={[classes.lowSubtitle, "font-20"].join(" ")}>
            {t("invest_adv_text")}
          </div>
          <div className={classes.lowTableWrapScroll}>
            <div className={classes.lowTableWrap}>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_01")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$1.75 ({t("invest_adv_classic")}) </p>
                  <p>$0.50 ({t("invest_adv_mini")})</p>
                </div>
              </div>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_02")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$6,00 </p>
                </div>
              </div>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_03")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$1,45</p>
                </div>
              </div>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_04")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$5,40</p>
                </div>
              </div>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_05")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$6,00</p>
                </div>
              </div>
              <div className={classes.lowTableWrapRow}>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>{t("invest_adv_li_06")}</p>
                </div>
                <div className={classes.lowTableWrapRowColumn}>
                  <p>$6,00 </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.lowImg}>
            <img src={img1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Low;
