import React from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";

const rows = [
    [
        'accounts.table.title_01',
        '$250',
        '$800',
        '$1000',
        '$5000',
    ],
    [
        'accounts.table.title_02',
        'USD EUR GBP JPY MYR IDR THB VND KWD CNY ZAR AED NGN',
        'USD EUR GBP JPY MYR IDR THB VND KWD CNY ZAR AED NGN',
        'USD EUR GBP JPY MYR IDR THB VND KWD CNY ZAR AED NGN',
        'USD EUR GBP JPY MYR',
    ],
    [
        'accounts.table.title_03',
        '1:1000',
        '1:500',
        '1:3000',
        '1:2500',
    ],
    [
        'accounts.table.title_04',
        '0.3',
        '0.1',
        '0.0',
        '0.0',
    ],
    [
        'accounts.table.title_05',
        '-',
        '-',
        'accounts.table.3lotside',
        '-',
    ],
    [
        'accounts.table.title_06',
        '+',
        '$800',
        '$1000',
        '+',
    ],
    [
        'accounts.table.title_07',
        'accounts.table.no_limit',
        'accounts.table.no_limit',
        'accounts.table.no_limit',
        'accounts.table.no_limit',
    ],
    [
        'accounts.table.title_08',
        '40%',
        '40%',
        '40%',
        '40%',
    ],
    [
        'accounts.table.title_09',
        '20%',
        '20%',
        '20%',
        '20%',
    ],
    [
        'accounts.table.title_10',
        'accounts.table.market',
        'accounts.table.market',
        'accounts.table.market',
        'accounts.table.market',
    ],
    [
        'accounts.table.title_11',
        '-',
        '-',
        '-',
        'accounts.table.20dep',
    ],
];

const Accounts = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.accounts}>
                <div className={classes.tableWrap}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Standard</th>
                                <th>Pro</th>
                                <th>Raw</th>
                                <th>VIP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) =>
                                <tr key={index}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            {t(col)}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Accounts;
