import React from "react";
import classes from "./Stock.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Stock = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.rates}>
      <div className="container">
        <div className={classes.ratesBody}>
          <div className={classes.ratesRow}>
            <div className={classes.ratesRowLeft}>
              <h2 className={[classes.ratesRowLeftTitle, "font-35"].join(" ")}>
                {t('rates_title')}
              </h2>
              <p
                className={[classes.ratesRowLeftSubtitle, "font-20"].join(" ")}
              >
                {t('rates_text_01')}
              </p>
              <p
                className={[classes.ratesRowLeftSubtitle, "font-20"].join(" ")}
              >
                {t('rates_text_02')}
              </p>

              <p
                className={[classes.ratesRowLeftSubtitle, "font-20"].join(" ")}
              >
                {t('rates_text_03')}
              </p>
              <div className={classes.ratesRowLeftBtn}>
                <Button>{t('btn_all_brokerage_fees')}</Button>
              </div>
            </div>
            <div className={classes.ratesRowRight}>
              <div className={classes.ratesRowRightColumn}>
                <div className={classes.ratesRowRightColumnItem}>
                  <h3
                    className={[
                      classes.ratesRowRightColumnItemTitle,
                      "font-30",
                    ].join(" ")}
                  >
                    {t('rates_card_01_title')}
                  </h3>
                  <p
                    className={[
                      classes.ratesRowRightColumnItemSubtitle,
                      "font-30",
                    ].join(" ")}
                  >
                    <span> 6,00 </span>
                    {t('rates_us_dollar')}
                  </p>
                </div>
              </div>
              <div className={classes.ratesRowRightColumn}>
                <div className={classes.ratesRowRightColumnItem}>
                  <h3
                    className={[
                      classes.ratesRowRightColumnItemTitle,
                      "font-30",
                    ].join(" ")}
                  >
                    {t('rates_card_02_title')}
                  </h3>
                  <p
                    className={[
                      classes.ratesRowRightColumnItemSubtitle,
                      "font-30",
                    ].join(" ")}
                  >
                    <span> 1,75 </span>
                    {t('rates_us_dollar')}
                  </p>
                </div>
              </div>
              <div className={classes.ratesRowRightColumn}>
                <div className={classes.ratesRowRightColumnItem}>
                  <h3
                    className={[
                      classes.ratesRowRightColumnItemTitle,
                      "font-30",
                    ].join(" ")}
                  >
                    {t('rates_card_03_title')}
                  </h3>
                  <p
                    className={[
                      classes.ratesRowRightColumnItemSubtitle,
                      "font-30",
                    ].join(" ")}
                  >
                    <span> 1,45 </span>
                    {t('rates_us_dollar')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
