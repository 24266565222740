import React from "react";
import classes from './Banner.module.scss';
import banner_img from '../../../assets/img/AboutPage/banner.png';
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        {t('about_banner_title')}
                    </h1>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>{t('about_banner_text_01')}</p>
                        <p>{t('about_banner_text_02')}</p>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_start_investing')}</Button>
                    </div>
                </div>
                <img src={banner_img} alt='The Ero Trade Online Broker Optimize Your Investments'/>
                <div className={classes.rect_01}></div>
                <div className={classes.rect_02}></div>
                <div className={classes.rect_03}></div>
            </div>
        </section>
    );
};

export default Banner;
