import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './StepsFor.module.scss';
import image from '../../../assets/img/StartPage/steps_for.png';
import { useTranslation } from "react-i18next";

const StepsFor = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.stepsFor}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('steps_for_title')}
                </h2>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>{t('steps_for_text_01')}</p>
                    <p>{t('steps_for_text_02')}</p>
                </div>
                <div className={classes.btn}>
                    <Button>{t('steps_for_btn')}</Button>
                </div>
                <img src={image} alt='Steps for opening a securities account'/>
            </div>
        </section>
    );
};

export default StepsFor;
