import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './TheOnlineBroker.module.scss';
import { useTranslation } from "react-i18next";

const TheOnlineBroker = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.theOnlineBroker}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('the_broker_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p>{t('the_broker_text_01')}</p>
                        <p>{t('the_broker_text_02')}</p>
                        <p>{t('the_broker_text_03')}</p>
                    </div>
                    <div className={classes.col}>
                        <p>{t('the_broker_text_04')}</p>
                        <p>{t('the_broker_text_05')}</p>
                        <p>{t('the_broker_text_06')}</p>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_start_trading')}</Button>
                </div>
            </div>
        </section>
    );
};

export default TheOnlineBroker;
