import React, { useEffect, useRef, useState } from "react";
import classes from "./Overview.module.scss";
import img1 from "../../../assets/img/fees/3/mob.png";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Overview = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col2.current, {
        top: 180,

        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: -40,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, [width]);
  return (
    <div className={classes.overview} ref={col1}>
      <div className="container">
        <div className={classes.overviewBody}>
          <div
            className={[classes.overviewLeftImg, ""].join(" ")}
            ref={col2}
          ></div>
          <div
            className={[classes.overviewRightImg, ""].join(" ")}
            ref={col3}
          ></div>

          <div className={[classes.overviewTitle, "font-35"].join(" ")}>
            {t("overview_title")}
          </div>
          <div className={[classes.overviewSubtitle, "font-20"].join(" ")}>
            {t("overview_text")}
          </div>
          <div className={classes.overviewTableWrapScroll}>
            <div className={classes.overviewTableWrap}>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p className={classes.fw600}>{t("overview_col_name_01")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p className={classes.fw600}>{t("overview_col_name_02")}</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_01")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")}</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_02")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")}</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_03")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")} </p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_04")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p> {t("overview_free")}</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_05")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")} </p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_06")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p> {t("overview_free")}*</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_07")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")}**</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_08")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p> {t("overview_free")}</p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_09")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")} </p>
                </div>
              </div>
              <div className={classes.overviewTableWrapRow}>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_row_10")}</p>
                </div>
                <div className={classes.overviewTableWrapRowColumn}>
                  <p>{t("overview_free")} </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.overviewText}>
            <Trans>{t("overview_desc")}</Trans>
          </div>
          <div className={classes.overviewImg}>
            <img src={img1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
