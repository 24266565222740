import React from "react";
import classes from './AboutInvest.module.scss';
import { useTranslation } from "react-i18next";

const AboutInvest = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.theOnlineBroker}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('about_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p>{t('about_text_01')}</p>
                        <p>{t('about_text_02')}</p>
                        <p>{t('about_text_03')}</p>
                    </div>
                    <div className={classes.col}>
                        <p>{t('about_text_04')}</p>
                        <p>{t('about_text_05')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutInvest;
