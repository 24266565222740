import $ from 'jquery';
import React, { useRef, useEffect, useState } from "react";
import classes from './HowSelect.module.scss';
import { useTranslation } from "react-i18next";

const HowSelect = () => {
    const { t } = useTranslation();
    const tab01Ref = useRef(null);
    const tab02Ref = useRef(null);
    const tab03Ref = useRef(null);
    const tab04Ref = useRef(null);
    const [tab01Active, setTab01Active] = useState(false);
    const [tab02Active, setTab02Active] = useState(false);
    const [tab03Active, setTab03Active] = useState(false);
    const [tab04Active, setTab04Active] = useState(false);
    useEffect(() => {
        $(tab01Ref.current).slideUp(0);
        $(tab02Ref.current).slideUp(0);
        $(tab03Ref.current).slideUp(0);
        $(tab04Ref.current).slideUp(0);
    }, []);
    return (
        <section className="container">
            <div className={classes.howSelect}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        {t('accounts.how_select.title')}
                    </h1>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('accounts.how_select.text')}
                    </p>
                </div>
                <div className={`${classes.spoilers} no-select`}>
                    {/* 1 */}
                    <div
                        className={`${classes.spoiler} ${tab01Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab01Ref.current).slideToggle(300);
                            setTab01Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                1
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                Standard {t('accounts.how_select.card.account')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab01Ref} className={classes.spoilerContent}>
                            <p>
                                {t('accounts.how_select.card_01.text')}
                            </p>
                        </div>
                    </div>
                    {/* 2 */}
                    <div
                        className={`${classes.spoiler} ${tab02Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab02Ref.current).slideToggle(300);
                            setTab02Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                2
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                Pro {t('accounts.how_select.card.account')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab02Ref} className={classes.spoilerContent}>
                            <p>
                                {t('accounts.how_select.card_02.text')}
                            </p>
                        </div>
                    </div>
                    {/* 3 */}
                    <div
                        className={`${classes.spoiler} ${tab03Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab03Ref.current).slideToggle(300);
                            setTab03Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                3
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                Raw Spread {t('accounts.how_select.card.account')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab03Ref} className={classes.spoilerContent}>
                            <p>
                                {t('accounts.how_select.card_03.text')}
                            </p>
                        </div>
                    </div>
                    {/* 4 */}
                    <div
                        className={`${classes.spoiler} ${tab04Active && classes.spoilerActive}`}
                        onClick={() => {
                            $(tab04Ref.current).slideToggle(300);
                            setTab04Active(value => !value);
                        }}
                    >
                        <div className={[classes.spoilerHeader, 'no-select'].join(' ')}>
                            <div className={classes.spoilerHeaderNum}>
                                4
                            </div>
                            <h3 className={classes.spoilerHeaderText}>
                                VIP {t('accounts.how_select.card.account')}
                            </h3>
                            <div className={classes.spoilerHeaderBtn}></div>
                        </div>
                        <div ref={tab04Ref} className={classes.spoilerContent}>
                            <p>
                                {t('accounts.how_select.card_04.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowSelect;
