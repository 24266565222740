import MainPage from '../pages/MainPage/MainPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import StartPage from '../pages/StartPage/StartPage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import FeesPage from '../pages/FeesPage/FeesPage';
import EducationPage from '../pages/EducationPage/EducationPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '*', element: MainPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/start', element: StartPage },
    { path: '/products', element: ProductsPage },
    { path: '/fees', element: FeesPage },
    { path: '/education', element: EducationPage },
    { path: '/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 0, path: '/accounts', text: "header_account_types" },
    { id: 1, path: '/start', text: "header_start" },
    { id: 2, path: '/products', text: "header_products" },
    { id: 3, path: '/fees', text: "header_fees" },
    { id: 4, path: '/education', text: "header_education" },
    { id: 5, path: '/about', text: "header_about_us" },
];
