import React from "react";
import classes from "./RowIcons.module.scss";
import img1 from "../../../assets/img/main/5/1.svg";
import img2 from "../../../assets/img/main/5/2.svg";
import img3 from "../../../assets/img/main/5/3.svg";
import img4 from "../../../assets/img/main/5/4.svg";
import img5 from "../../../assets/img/main/5/5.svg";
import img6 from "../../../assets/img/main/5/6.svg";
import img7 from "../../../assets/img/main/5/7.svg";
import img8 from "../../../assets/img/main/5/8.svg";
import { useTranslation } from "react-i18next";

const RowIcons = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.row}>
      <div className="container">
        <div className={classes.rowContentWrapper}>
          <div className={classes.rowContent}>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img1} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_01')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img2} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_02')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img3} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_03')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img4} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_04')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img5} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_05')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img6} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_06')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img7} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_07')}
              </p>
            </div>
            <div className={classes.rowContentColumn}>
              <div className={classes.rowContentColumnIcon}>
                <img src={img8} alt="" />
              </div>
              <p
                className={[classes.rowContentColumnText, "font-18"].join(" ")}
              >
                {t('the_possibilities_li_08')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowIcons;
