import React, { useRef, useEffect, useState } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/main/1/background.png";
import imgAdaptive from "../../../assets/img/main/1/1.png";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const First = () => {
  const eyeRef = useRef(null);
  const bullRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: "underbanner_card_01_title",
      text: "underbanner_card_01_text",
      active: true,
    },
    {
      id: 2,
      title: "underbanner_card_02_title",
      text: "underbanner_card_02_text",
      active: false,
    },
    {
      id: 3,
      title: "underbanner_card_03_title",
      text: "underbanner_card_03_text",
      active: false,
    },
  ]);
  let eyeScale = 0.9;
  let eyeX = 0;
  let eyeY = 35;
  let bullScale = 0.9;
  let bullX = 0;
  let bullY = 33;
  if (width < 1651) {
    eyeX = 0;
    eyeY = 30;
    bullX = 0;
    bullY = 28;
  }
  if (width < 1421) {
    eyeX = 0;
    eyeY = 24;
    bullX = 0;
    bullY = 22;
  }
  useEffect(() => {
    gsap.timeline().to(eyeRef.current, {
      scale: eyeScale,
      y: eyeY,
      x: eyeX,
      delay: 0.5,
    });
    gsap.timeline().to(bullRef.current, {
      scale: bullScale,
      y: bullY,
      x: bullX,
      delay: 0.5,
    });
    // eslint-disable-next-line
  }, [width]);

  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstRow}>
            <div className={classes.firstRowLeft}>
              <h2 className={[classes.firstRowLeftTitle, "font-40"].join(" ")}>
                {t("main_banner_title")}
              </h2>
              <p
                className={[classes.firstRowLeftSubtitle, "font-23"].join(" ")}
              >
                <Trans>{t("main_banner_text")}</Trans>
              </p>
              <div className={classes.firstRowLeftSignBtn}>
                <Button>{t("btn_sign_up_and_begin")}</Button>
              </div>

              <ul className={[classes.firstRowLeftTabs].join(" ")}>
                {tabs.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className={
                        i.active
                          ? [classes.firstRowLeftTab, classes.active].join(" ")
                          : classes.firstRowLeftTab
                      }
                    >
                      <li
                        className={[
                          classes.firstRowLeftTabTitle,
                          "font-23",
                        ].join(" ")}
                        onClick={() => {
                          setTabs(
                            tabs.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                        }}
                      >
                        {t(i.title)}
                      </li>
                      <p className={classes.firstRowLeftTabText}>
                        <span className="font-20">{t(i.text)}</span>
                      </p>
                    </div>
                  );
                })}
              </ul>
            </div>
            <div className={classes.firstRowRight}>
              <div className={classes.firstRowRightImg}>
                {width > 1220 && <img src={img1} alt="" />}
                {width < 1221 && <img src={imgAdaptive} alt="" />}
                <div
                  className={[classes.firstRowRightBull, ""].join(" ")}
                  ref={bullRef}
                ></div>
                <div
                  className={[classes.firstRowRightEye, ""].join(" ")}
                  ref={eyeRef}
                ></div>
              </div>
            </div>
            <div className={[classes.firstRowLeftTabs, classes.mob].join(" ")}>
              {tabs.map((i) => {
                return (
                  <div
                    key={i.id}
                    className={
                      i.active
                        ? [classes.firstRowLeftTab, classes.active].join(" ")
                        : classes.firstRowLeftTab
                    }
                  >
                    <div
                      className={[classes.firstRowLeftTabTitle, "font-23"].join(
                        " "
                      )}
                      onClick={() => {
                        setTabs(
                          tabs.map((subI) => {
                            if (i.id === subI.id) {
                              return { ...subI, active: true };
                            } else {
                              return { ...subI, active: false };
                            }
                          })
                        );
                      }}
                    >
                      {t(i.title)}
                    </div>
                    <div className={classes.firstRowLeftTabText}>
                      <span className="font-20">{t(i.text)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
