import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './InvestIn.module.scss';
import { useTranslation } from "react-i18next";

const InvestIn = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.investIn}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('invest_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p><b>{t('invest_subtitle')}</b></p>
                        <p>{t('invest_text_01')}</p>
                    </div>
                    <div className={classes.col}>
                        <p>{t('invest_text_02')}</p>
                        <p>{t('invest_text_03')}</p>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_start_investing')}</Button>
                </div>
                <div className={classes.rect_01}></div>
                <div className={classes.rect_02}></div>
                <div className={classes.rect_03}></div>
                <div className={classes.rect_04}></div>
            </div>
        </section>
    );
};

export default InvestIn;
