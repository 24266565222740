import React from "react";
import classes from './InvestFair.module.scss';
import award_01 from '../../../assets/img/AboutPage/award_01.png';
import award_02 from '../../../assets/img/AboutPage/award_02.png';
import award_03 from '../../../assets/img/AboutPage/award_03.png';
import { useTranslation } from "react-i18next";

const InvestFair = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.theOnlineBroker}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('invest_fair_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p>{t('invest_fair_text_01')}</p>
                        <p>{t('invest_fair_text_02')}</p>
                    </div>
                    <ul className={classes.col}>
                        <li>{t('invest_fair_li_01')}</li>
                        <li>{t('invest_fair_li_02')}</li>
                        <li>{t('invest_fair_li_03')}</li>
                    </ul>
                </div>
                <div className={classes.awards}>
                    <img className={classes.award_01} src={award_01} alt=''/>
                    <img className={classes.award_02} src={award_02} alt=''/>
                    <img className={classes.award_03} src={award_03} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default InvestFair;
