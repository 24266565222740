import React from "react";
import classes from './StartInvesting.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        id: 1,
        title: '150',
        text: 'start_investing_card_01_text',
    },
    {
        id: 2,
        title: '33',
        text: 'start_investing_card_02_text',
    },
    {
        id: 3,
        title: '26',
        text: 'start_investing_card_03_text',
    },
];

const StartInvesting = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.startInvesting}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('start_investing_title')}
                </h2>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>{t('start_investing_text_01')}</p>
                    <p>{t('start_investing_text_02')}</p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card) =>
                        <div key={card.id} className={classes.card}>
                            <p className={classes.cardTitle}>
                                {card.title}
                            </p>
                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default StartInvesting;
