import React from "react";
import classes from './TheInvest.module.scss';
import { useTranslation } from "react-i18next";

const TheInvest = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.theOnlineBroker}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t('the_invest_title')}
                </h2>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <div className={classes.col}>
                        <p>{t('the_invest_text_01')}</p>
                        <p>{t('the_invest_text_02')}</p>
                    </div>
                    <div className={classes.col}>
                        <p>{t('the_invest_text_03')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TheInvest;
