import React from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import banner_img from '../../../assets/img/AccountsPage/banner.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-35'].join(' ')}>
                            {t('accounts.banner.title')}
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('accounts.banner.text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>{t('btn_start_investing')}</Button>
                        </div>
                    </div>
                    <img src={banner_img} alt='The Ero Trade Online Broker Optimize Your Investments' />
                </div>
            </div>
        </section>
    );
};

export default Banner;
