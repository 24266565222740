import React from "react";
import classes from "./Online.module.scss";
import { Trans, useTranslation } from "react-i18next";
const Online = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.online}>
      <div className={classes.onlineBody}>
        <div className="container">
          <h2 className={[classes.onlineTitle, "font-35"].join(" ")}>
            {t('the_online_title')}
          </h2>
          <p className={[classes.onlineText, "font-20"].join(" ")}>
            <Trans>
              {t('the_online_text')}
            </Trans>
          </p>
          <div className={classes.onlineRowWrapper}>
            <div className={classes.onlineRow}>
              <div
                className={[
                  classes.onlineRowColumn,
                  classes.onlineRowColumn1,
                ].join(" ")}
              >
                <div className={classes.onlineRowColumnItem}>
                  <h3
                    className={[
                      classes.onlineRowColumnItemTitle,
                      "font-18",
                    ].join(" ")}
                  >
                    <Trans>{t('the_online_card_01_title')}</Trans>
                  </h3>
                  <p
                    className={[
                      classes.onlineRowColumnItemText,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('the_online_card_01_text')}
                  </p>
                </div>
              </div>
              <div
                className={[
                  classes.onlineRowColumn,
                  classes.onlineRowColumn2,
                ].join(" ")}
              >
                <div className={classes.onlineRowColumnItem}>
                  <h3
                    className={[
                      classes.onlineRowColumnItemTitle,
                      "font-18",
                    ].join(" ")}
                  >
                    <Trans>{t('the_online_card_02_title')}</Trans>
                  </h3>
                  <p
                    className={[
                      classes.onlineRowColumnItemText,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('the_online_card_02_text')}
                  </p>
                </div>
              </div>
              <div
                className={[
                  classes.onlineRowColumn,
                  classes.onlineRowColumn3,
                ].join(" ")}
              >
                <div className={classes.onlineRowColumnItem}>
                  <h3
                    className={[
                      classes.onlineRowColumnItemTitle,
                      "font-18",
                    ].join(" ")}
                  >
                    <Trans>{t('the_online_card_03_title')}</Trans>
                  </h3>
                  <p
                    className={[
                      classes.onlineRowColumnItemText,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('the_online_card_03_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Online;
