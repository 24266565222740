import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Banner.module.scss";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.bannerContainer, "container"].join(" ")}>
            <h1 className={['font-35', classes.bannerTitle].join(" ")}>{t('education_banner_title')}</h1>
            <div className={['row', classes.bannerRowMb70].join(" ")}>
                <div className="col-12 col-md4-6">
                    <div className={classes.itemWrapLeft}>
                        <p className={['font-20', classes.bannerText].join(" ")}>{t('education_banner_text_01')}</p>
                        <p className={['font-20', classes.bannerText].join(" ")}>{t('education_banner_text_02')}</p>
                        <p className={['font-20', classes.bannerText].join(" ")}>{t('education_banner_text_03')}</p>
                    </div>
                </div>
                <div className="col-12 col-md4-6">
                    <div className={classes.itemWrapRight}>
                        <p className={['font-20', classes.bannerText].join(" ")}>{t('education_banner_text_04')}</p>
                        <p className={['font-20', classes.bannerText].join(" ")}>{t('education_banner_text_05')}</p>
                    </div>
                </div>
            </div>
            <div className={classes.bannerBtnWrap}>
                <Button>{t('btn_start_trading')}</Button>
            </div>
        </div>
    )
}

export default Banner;