import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.png";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../router/index";
import { Link, NavLink } from "react-router-dom";
import "./Header.scss";
import $ from "jquery";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

const Header = () => {
  const { t } = useTranslation();

  global.text = t;
  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
  ];
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);

  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));

  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link
                to="/"
                onClick={() => {
                  setBurgerA(false);
                  $("body").removeClass("lock");
                }}
              >
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div className={classes.headerRowCenter}>
                  <div className={classes.wrap2Over}>
                    <div className={classes.wrap2}>
                      {headerRoutes.map((navI) => {
                        return (
                          <NavLink
                            to={navI.path}
                            className={[
                              classes.headerNavLink,
                              "headerNavLink",
                            ].join(" ")}
                            key={navI.id}
                            onClick={() => {
                              setBurgerA(false);
                              $("body").removeClass("lock");
                            }}
                          >
                            <Trans>
                              {navI.text}
                            </Trans>
                          </NavLink>
                        );
                      })}
                      <div
                        className={[
                          classes.headerRowRightLocalization,
                          classes.mob,
                        ].join(" ")}
                        onClick={() => {
                          setLocalA(!localA);
                        }}
                      >
                        <div
                          className={
                            localA
                              ? burgerA
                                ? [
                                    classes.headerRowRightLocalizationTitle,
                                    classes.active,
                                    classes.white,
                                  ].join(" ")
                                : [
                                    classes.headerRowRightLocalizationTitle,
                                    classes.active,
                                  ].join(" ")
                              : burgerA
                              ? [
                                  classes.headerRowRightLocalizationTitle,
                                  classes.white,
                                ].join(" ")
                              : classes.headerRowRightLocalizationTitle
                          }
                        >
                          {defaultOption}
                          <img src={arrow} alt="" />
                        </div>
                        <div
                          className={
                            localA
                              ? [
                                  classes.headerRowRightLocalizationItems,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowRightLocalizationItems
                          }
                        >
                          {languages.map((i) => {
                            return (
                              <p
                                key={i.value}
                                onClick={() => {
                                  i18n.changeLanguage(i.value);
                                  setDefaultOption(i.value);
                                }}
                              >
                                {i.value}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classes.headerRowRightLocalization}
                onClick={() => {
                  setLocalA(!localA);
                }}
              >
                <div
                  className={
                    localA
                      ? burgerA
                        ? [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                            classes.white,
                          ].join(" ")
                        : [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                          ].join(" ")
                      : burgerA
                      ? [
                          classes.headerRowRightLocalizationTitle,
                          classes.white,
                        ].join(" ")
                      : classes.headerRowRightLocalizationTitle
                  }
                >
                  {defaultOption}
                  <img src={arrow} alt="" />
                </div>
                <div
                  className={
                    localA
                      ? [
                          classes.headerRowRightLocalizationItems,
                          classes.active,
                        ].join(" ")
                      : classes.headerRowRightLocalizationItems
                  }
                >
                  {languages.map((i) => {
                    return (
                      <p
                        key={i.value}
                        onClick={() => {
                          i18n.changeLanguage(i.value);
                          setDefaultOption(i.value);
                        }}
                      >
                        {i.value}
                      </p>
                    );
                  })}
                </div>
              </div>

              <a href="https://user.erotrade.ltd/login" className={classes.headerRowRightSignBtn}>
                <Trans>{t('header_login')}</Trans>
              </a>

              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  setBurgerA(!burgerA);
                  if ($("body").hasClass("lock")) {
                    $("body").removeClass("lock");
                  } else {
                    $("body").addClass("lock");
                  }
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
