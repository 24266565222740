import React, { useEffect, useState } from "react";
import classes from "./Banner.module.scss";
import bear from "../../../assets/img/StartPage/Banner/bear.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [hover, setHover] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.banner}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-35"].join(" ")}>
            {t("start_banner_title")}
          </h1>
          <p className={[classes.text_01, "font-20"].join(" ")}>
            {t("start_banner_subtitle")}
          </p>
          <p className={[classes.text_02, "font-20"].join(" ")}>
            {t("start_banner_text")}
          </p>
          <div
            className={classes.btn}
            onMouseLeave={() => {
              setHover(true);
            }}
            onMouseEnter={() => {
              setHover(false);
            }}
          >
            <Button>{t("btn_open_an_account_now")}</Button>
          </div>
        </div>
        {width > 1220 && (
          <div
            className={
              hover
                ? [classes.hoverAnim, classes.hover].join(" ")
                : classes.hoverAnim
            }
          >
            <div className={[classes.pc_bear, ""].join(" ")}></div>
            <div className={[classes.whiteSquare1, ""].join(" ")}></div>
            <div className={[classes.whiteSquare2, ""].join(" ")}></div>
            <div className={[classes.whiteSquare3, ""].join(" ")}></div>
            <div className={[classes.blueSquare1, ""].join(" ")}></div>
            <div className={[classes.blueSquare2, ""].join(" ")}></div>
            <div className={[classes.blueSquare3, ""].join(" ")}></div>
          </div>
        )}
        {width < 1221 && (
          <div>
            <div className={classes.rect_01}></div>
            <div className={classes.rect_02}></div>
            <div className={classes.rect_03}></div>
            <div className={classes.rect_04}></div>
            <div className={classes.rect_05}></div>
            <img className={classes.bear} src={bear} alt="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default Banner;
