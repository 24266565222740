import React from "react";
import classes from "./Brokerage.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Brokerage = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.brokerage}>
      <div className={classes.brokerageBody}>
        <div className="container">
          <h2 className={[classes.brokerageRowLeftTitle, "font-35"].join(" ")}>
            {t('fees_banner_title')}
          </h2>
          <div className={classes.brokerageRow}>
            <div className={classes.brokerageRowLeft}>
              <div className={classes.brokerageRowLeftWrapper}>
                <p
                  className={[
                    classes.brokerageRowLeftSubtitle,
                    "font-20",
                    classes.fw500,
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('fees_banner_text_01')}
                </p>
                <ul className={[classes.brokerageRowLeftList, classes.mb15].join(" ")}>
                  <li className={[classes.brokerageRowLeftItem, "font-20"].join(" ")}>
                    {t('fees_banner_li_01')}
                  </li>
                  <li className={[classes.brokerageRowLeftItem, "font-20"].join(" ")}>
                    {t('fees_banner_li_02')}
                  </li>
                  <li className={[classes.brokerageRowLeftItem, "font-20"].join(" ")}>
                    {t('fees_banner_li_03')}
                  </li>
                  <li className={[classes.brokerageRowLeftItem, "font-20"].join(" ")}>
                    {t('fees_banner_li_04')}
                  </li>
                </ul>
                <p className={[classes.brokerageRowLeftSubtitle, "font-20"].join(" ")}>
                  {t('fees_banner_text_02')}
                </p>
              </div>
              <div className={classes.brokerageRowLeftBot}>
                <div className={classes.brokerageRowLeftSubs}>
                  <Button>{t('btn_start_investing')}</Button>
                </div>
              </div>
            </div>
            <div className={classes.brokerageRowRight}>
              <div className={classes.brokerageRowRightWrapper}>
                <p
                  className={[
                    classes.brokerageRowRightSubtitle,
                    "font-20",
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('fees_banner_text_03')}
                </p>
                <p
                  className={[
                    classes.brokerageRowRightSubtitle,
                    "font-20",
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('fees_banner_text_04')}
                </p>
                <p
                  className={[
                    classes.brokerageRowRightSubtitle,
                    "font-20",
                    classes.mb15,
                  ].join(" ")}
                >
                  {t('fees_banner_text_05')}
                </p>
              </div>
            </div>
          </div>
          <div className={[classes.brokerageRowLeftBot, classes.mob].join(" ")}>
            <div className={classes.brokerageRowLeftSubs}>
              <Button>{t('btn_start_investing')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brokerage;
